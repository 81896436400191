import React, { Component } from 'react';
import { compose } from 'recompose';
import { withAuthorization, withEmailVerification } from "../../containers/Session";
import { withFirebase } from "../../containers/Firebase";
import { withRouter, Link } from 'react-router-dom';

import { Layout, Menu, Icon, Row, Col, Card, Table, Tag, Descriptions, Divider, Button, Skeleton, List, Tooltip, Dropdown, Drawer, Input, Radio, Checkbox, Switch } from 'antd'

import axios from 'axios'
import moment from 'moment-timezone';
import 'moment/locale/pt-br';

import _ from "lodash";

import * as ROUTES from '../../constants/routes';
import { createBrowserHistory } from "history";
import Highlighter from 'react-highlight-words';

const INITIAL_STATE = {
    isLoading: true,
    originalData: [],
    funcionarios: [],
    currentDataSource: [],
    status: ["Sem avaliação", "OK", "OK - Desatualizado", "Risco", "Risco - Desatualizado"],
    statusPessoa: [],
    statusDoenca: [],
}

const { Content, Sider } = Layout;

class Dashboard extends Component {

    constructor() {
        super()
        this.state = { ...INITIAL_STATE }
    }

    componentDidMount() {
        if (!localStorage.getItem("authUser")) {
            this.props.history.push(ROUTES.SIGN_IN);
            return;
        }

        Promise.all([
            this.getAxiosInstance().get('/common/statuspessoa'),
            this.getAxiosInstance().get('/common/statusdoenca'),
            this.getAxiosInstance().get('/funcionario'),
        ]).then((values) => {        
            this.setState({
                isLoading: false,
                statusPessoa: values[0].data,
                statusDoenca: values[1].data,
                originalData: values[2].data,
                funcionarios: values[2].data,
                currentDataSource: values[2].data
            });
        });
    }

    getAxiosInstance() {
        return axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            headers: {
                "Authorization": "Bearer " + (localStorage.getItem('authUser') && JSON.parse(localStorage.getItem('authUser')).jwtToken),
            },

        })
    }

    filter = (event) => {
        const filter = event.target.value;
        const newDataSource = _.filter(this.state.originalData, e => 
            e.nome.toLowerCase().includes(filter.toLowerCase()) || 
            e.unidade.toLowerCase().includes(filter.toLowerCase()) || 
            e.cpf.includes(filter));
        this.setState({
            funcionarios: newDataSource,
            currentDataSource: newDataSource
        });
    }
    
    byString = function(o, s) {
        s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
        s = s.replace(/^\./, '');           // strip a leading dot
        var a = s.split('.');
        for (var i = 0, n = a.length; i < n; ++i) {
            var k = a[i];
            if (k in o) {
                o = o[k];
            } else {
                return;
            }
        }
        return o;
    }

    getColumnSearchProps = (dataIndex, textFormatter) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                this.searchInput = node;
              }}
              placeholder={`Filtar ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              icon="search"
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Filtar
            </Button>
            <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Limpar
            </Button>
          </div>
        ),
        filterIcon: filtered => (
          <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) => {
          const data = this.byString(record, dataIndex);
          return (textFormatter ? textFormatter(data) : data ? data.toString() : "")
            .toLowerCase()
            .includes(value.toLowerCase());
        },
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => this.searchInput.select());
          }
        },
        render: value =>
          this.state.searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[this.state.searchText]}
              autoEscape
              textToHighlight={textFormatter ? textFormatter(value) : value.toString()}
            />
          ) : (
            textFormatter ? textFormatter(value) : value.toString()
          ),
    })

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
          searchText: selectedKeys[0],
          searchedColumn: dataIndex,
        });
    }

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    }

    onTableDataSourceChange = (pagination, filters, sorter, extra) => {
        this.setState({ currentDataSource: extra.currentDataSource });
    }

    render() {
        return (
            <Layout style={{ minHeight: '100vh' }} >
                <Sider style={{ background: "#fff", zIndex: 0 }} collapsed={true}>
                    <div style={{ padding: 10, textAlign: "center" }}>
                        <img width="40px" src={require('./../../assets/logo-bbm-small.png')} alt="logo-empresa" />
                    </div>
                    <Menu mode="inline">
                        <Menu.Item key="1">
                            <Icon type="dashboard" />
                            <span>Dashboard</span>
                            <Link to={ROUTES.DASHBOARD} />
                        </Menu.Item>
                        <Menu.Item key="2" path>
                            <Icon type="schedule" />
                            <span>Funcionários</span>
                            <Link to={ROUTES.FUNCIONARIOS} />
                        </Menu.Item>
                        <Menu.Item key="3" path>
                            <a href={ROUTES.INDICADORES} target="_blank" rel="noopener noreferrer">
                                <Icon type="area-chart" />
                                <span>Indicadores</span>
                            </a>
                        </Menu.Item>
                        <Menu.Item className={"logout-menu-item"} key="3" onClick={this.props.firebase.doSignOut} >
                            <Icon type="logout" />
                            <span>Logout</span>
                        </Menu.Item>
                    </Menu>
                </Sider>
                <Content style={{ padding: '50px' }}>
                    <Row>
                        <Col md={24}>
                            <Card>
                                {
                                    this.state.isLoading ?
                                        <Skeleton active />
                                        :
                                        <div>
                                            <div >
                                                <Row>
                                                    <Button onClick={() => { window.open(process.env.REACT_APP_DIAGNOSTICO_URL, '_blank'); }} icon={"plus"} className={"btn-brand mr-15"} type="primary" style={{ marginBottom: 16 }}>
                                                        Novo diagnóstico
                                                    </Button>
                                                    <Button onClick={() => { window.open(`${process.env.REACT_APP_API_URL}/funcionario/xls`, '_blank'); }} icon={"file-excel"} className={"btn-neutral"} type="primary" style={{ marginBottom: 16 }}>
                                                        Exportar para excel
                                                    </Button>
                                                    <Input placeholder="Filtrar por CPF, Nome ou Filial" name="filtro" className="form-control" onChange={this.filter} style={{ width: 400, marginBottom: 16, marginLeft: 16 }} />
                                                    <label style={{float: 'right'}}>Registros: {this.state.currentDataSource.length}</label>
                                                </Row>
                                            </div>
                                            <Table
                                                pagination={{ pageSize: 50 }}
                                                dataSource={this.state.funcionarios}
                                                size="small"                                                
                                                locale={{
                                                    emptyText: "Ainda não temos nenhum diagnóstico"
                                                }}
                                                onChange={this.onTableDataSourceChange}
                                                columns={[
                                                    {
                                                        title: 'Matrícula',
                                                        dataIndex: 'matricula',
                                                        key: 'matricula',
                                                        sorter: (a, b) => a.matricula === b.matricula ? 0  : (a.matricula < b.matricula ? -1 : 1),                                                        
                                                    },
                                                    {
                                                        title: 'CPF',
                                                        dataIndex: 'cpf',
                                                        sorter: (a, b) => a.cpf.localeCompare(b.cpf),
                                                        ...this.getColumnSearchProps('cpf'),
                                                    },
                                                    {
                                                        title: 'Nome',
                                                        dataIndex: 'nome',
                                                        sorter: (a, b) => a.nome.localeCompare(b.nome),
                                                        ...this.getColumnSearchProps('nome'),
                                                    },
                                                    {
                                                        title: 'Status',
                                                        dataIndex: 'diagnosis.status',
                                                        sorter: (a, b) => a.diagnosis.status.localeCompare(b.diagnosis.status),
                                                        filters: this.state.status.map(e => { return {text: e, value: e}}),
                                                        onFilter: (filterValue, record) => record.diagnosis.status === filterValue,
                                                    },
                                                    {
                                                        title: 'Grau Risco',
                                                        dataIndex: 'statusAtual.label',
                                                        key: 'statusAtual.label',
                                                        render: statusAtual => <Tag>{statusAtual}</Tag>,
                                                        onFilter: (filterValue, record) => record.statusAtual && record.statusAtual.id === filterValue,
                                                        filters: this.state.statusPessoa.map(e => {
                                                            return {
                                                                text: e.label,
                                                                value: e.id
                                                            }
                                                        }),
                                                    },
                                                    {
                                                        title: 'Status COVID',
                                                        dataIndex: 'statusCovid.label',
                                                        key: 'statusCovid.label',
                                                        render: statusCovid => <Tag>{statusCovid}</Tag>,
                                                        onFilter: (filterValue, record) => record.statusCovid && record.statusCovid.id === filterValue,
                                                        filters: this.state.statusDoenca.map(e => {
                                                            return {
                                                                text: e.label,
                                                                value: e.id
                                                            }
                                                        }),
                                                    },
                                                    {
                                                        title: 'Ultima avaliação',
                                                        dataIndex: 'diagnosis.createdAt',
                                                        sorter: (a, b) => { 
                                                            if (a.diagnosis.createdAt && b.diagnosis.createdAt) {
                                                                return a.diagnosis.createdAt === b.diagnosis.createdAt
                                                                    ? 0 
                                                                    : a.diagnosis.createdAt < b.diagnosis.createdAt ? -1 : 1;
                                                            } else if (a.diagnosis.createdAt) {
                                                                return 1;
                                                            } else if (b.diagnosis.createdAt) {
                                                                return -1;
                                                            } else {
                                                                return 0;
                                                            }
                                                        },
                                                        ...this.getColumnSearchProps('diagnosis.createdAt', createdAt => createdAt ? moment.utc(createdAt).tz("America/Sao_Paulo").format("DD/MM/YYYY HH:mm") : ""),
                                                    },
                                                    {
                                                        title: 'Celular',
                                                        dataIndex: 'contato.celular',
                                                        sorter: (a, b) => a.contato.celular.localeCompare(b.celular),
                                                        ...this.getColumnSearchProps('contato.celular'),
                                                    },
                                                    {
                                                        title: 'Filial',
                                                        dataIndex: 'unidade',
                                                        sorter: (a, b) => a.unidade.localeCompare(b.unidade),
                                                        ...this.getColumnSearchProps('unidade'),
                                                    }                                                    
                                                ]}
                                            >
                                            </Table>
                                        </div>
                                }
                            </Card>
                        </Col>
                    </Row>
                </Content >
            </Layout >
        );
    }
}

// just check if its not null
const condition = authUser => {
    if (!authUser) {
        createBrowserHistory().push(ROUTES.SIGN_IN);
        window.location.reload();
    }
    return !!authUser;
}
export default compose(
    withEmailVerification,
    withAuthorization(condition),
    withRouter,
    withFirebase,
)(Dashboard);