import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import Alert from 'react-s-alert';

import { withFirebase } from '../../containers/Firebase';
import * as ROUTES from '../../constants/routes';
import * as ERRORS from '../../constants/errors';

import { Form, Icon, Input, Button } from 'antd';

import logo from "../../assets/logo.png";
import "./index.css";

const PasswordChangePage = () => (
    <div className={"login-wrapper"}>
        <PasswordChangeForm />
    </div>
);

const INITIAL_STATE = {
    passwordOne: '',
    passwordTwo: '',
    error: null,
    errorMessage: null,
    isLoading: false,
};

class PasswordChangeFormBase extends Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }

    onSubmit = event => {
        const { passwordOne } = this.state;

        const url = this.props.location.pathname.split("/");
        const resetPwdId = url[url.length - 1];

        this.setState({
            isLoading: true
        })

        this.props.firebase
            .doPasswordUpdate(resetPwdId, passwordOne)
            .then(() => {
                this.setState({
                    ...INITIAL_STATE,
                    isLoading: false,
                    errorMessage: null
                });

                Alert.success("Sua senha foi atualizada. Acesso o sistema com sua nova senha :)", {
                    position: 'bottom-right',
                    effect: 'stackslide',
                });

                this.props.history.push(ROUTES.SIGN_IN);
            })
            .catch(error => {
                const errorMessage = error && error.response && error.response.data && error.response.data.code
                    ? ERRORS.auth[error.response.data.code]
                    : error.message;
                this.setState({
                    error,
                    errorMessage,
                    isLoading: false
                });
            });

        event.preventDefault();
    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const { passwordOne, passwordTwo, error, errorMessage } = this.state;

        const isInvalid =
            passwordOne !== passwordTwo || passwordOne === '';

        return (
            <div className="login-panel">
                <div className="text-center">
                    <img width={"200px"} className="mb-20" src={logo} alt="logo" />
                </div>
            <form onSubmit={this.onSubmit}>
                <Form.Item>
                    {getFieldDecorator('passwordOne', {
                            rules: [{ required: true, message: 'Insira sua senha' }],
                    })(
                        <Input
                            name="passwordOne"
                            value={passwordOne}
                            onChange={this.onChange}
                            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                            type="password"
                            placeholder="Nova senha"
                            size="large"
                        />,
                    )}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('passwordTwo', {
                            rules: [{ required: true, message: 'Insira sua senha' }],
                    })(
                        <Input
                            name="passwordTwo"
                            value={passwordTwo}
                            onChange={this.onChange}
                            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                            type="password"
                            placeholder="Confirmação"
                            size="large"
                        />,
                    )}
                </Form.Item>
                <Form.Item>
                    <Button disabled={isInvalid} size="large" block loading={this.state.isLoading} type="primary" htmlType="submit" className="login-form-button btn-brand">
                    Trocar minha senha
                    </Button>
                </Form.Item>
                {errorMessage && <p>{errorMessage}</p>}
            </form>
            </div>
        );
    }
}

// export default withFirebase(PasswordChangeForm);
const WrappedLoginForm = Form.create({ name: 'login_form' })(PasswordChangeFormBase)
const PasswordChangeForm = compose(
    withRouter,
    withFirebase,
)(WrappedLoginForm);

export default PasswordChangePage;

export { PasswordChangeForm };