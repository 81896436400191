export const LANDING = '/landing';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const ADMIN = '/admin';
export const PASSWORD_FORGET = '/pw-forget';
export const PASSWORD_CHANGE = '/pw-change';

export const DASHBOARD = '/dashboard';
export const FUNCIONARIOS = '/funcionarios';
export const INDICADORES = process.env.REACT_APP_LINK_INDICADORES;
export const INICIO_JORNADA = process.env.REACT_APP_LINK_INICIO_JORNADA;