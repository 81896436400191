import FirebaseContext, {
    withFirebase
} from './context';
import Firebase from './config';

export default Firebase;

export {
    FirebaseContext,
    withFirebase
};