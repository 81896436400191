import React, { Component } from 'react';
import { compose } from 'recompose';
import { withAuthorization, withEmailVerification } from "../../containers/Session";
import { withFirebase } from "../../containers/Firebase";
import { withRouter, Link } from 'react-router-dom';

import { Layout, Menu, Icon, Row, Col, Card, Table, Tag, Descriptions, Divider, Button, Skeleton, List, Tooltip, Dropdown, Drawer, Input, Radio, Checkbox, Switch, Select } from 'antd'

import Alert from 'react-s-alert';

import _ from "lodash";

import axios from 'axios'
import moment from 'moment-timezone';
import 'moment/locale/pt-br';

import * as ROUTES from '../../constants/routes';
import { createBrowserHistory } from "history";
import Highlighter from 'react-highlight-words';

const { Option } = Select;

const INITIAL_STATE = {
    isLoading: true,
    diagnostics: null,
    currentDataSource: [],
    isNewNotationDrawerOpen: false,
    isEditDrawerOpen: false,
    isEditing: false,
    selectedPatient: null,
    anotations: null,
    statusAtualId: null,
    touchpoints: [],
    isRegisteringAnnotation: false,
    hastes: [],
    status: [],
    statusPessoa: [],
    statusDoenca: [],
    medicalUnit: [],
    selectedMonth: new Date().getMonth(),
    selectedYear: new Date().getFullYear(),
}

const { Content, Sider } = Layout;

const { TextArea } = Input;

const RESPIRATORY_CHART = [
    "Tosse",
    "Dificuldade para respirar",
    "Coriza"
]

const CHRONIC_CHART = [
    "Diabetes",
    "Gestantes",
    "Doenças pulmonares (Asma, Bronquite)",
    "Doença renais",
    "HIV",
    "Cancer",
    "Asma ou afins",
    "Doença cardíaca"
]

const TEMPERATURE = {
    "abaixo_de_37.5": "Abaixo de 37.5°",
    "igual_ou_acima_de_37.6": "Igual ou acima de 37.6°",
    "acima_de_39": "Acima de 39.0°",
    "sem_leitura": "Sem leitura",
}

const MESES = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
]

const ANOS = (() => {
    var arr = [];
    for (var x = 2020; x <= new Date().getFullYear(); x++) {
        arr.push(x);
    }
    return arr;
})();

class Dashboard extends Component {

    constructor() {
        super()
        this.state = { ...INITIAL_STATE }
        this.grid = React.createRef();
    }

    componentDidMount() {
        if (!localStorage.getItem("authUser")) {
            this.props.history.push(ROUTES.SIGN_IN);
            return;
        }

        Promise.all([
            this.getAxiosInstance().get('/common/hastes'),
            this.getAxiosInstance().get('/common/status'),
            this.getAxiosInstance().get('/medicalUnit'),
            this.getAxiosInstance().get('/diagnosis'),
            this.getAxiosInstance().get('/common/statuspessoa'),
            this.getAxiosInstance().get('/common/statusdoenca'),
        ]).then((values) => {
            this.setState({
                hastes: values[0].data,
                status: values[1].data,
                medicalUnit: values[2].data,
                isLoading: false,
                diagnostics: values[3].data,
                currentDataSource: values[3].data,
                statusPessoa: values[4].data,
                statusDoenca: values[5].data
            })
        })
    }

    getAxiosInstance() {
        return axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            headers: {
                "Authorization": "Bearer " + (localStorage.getItem('authUser') && JSON.parse(localStorage.getItem('authUser')).jwtToken),
            },

        })
    }

    handleOpenExportDrawer = () => {

        this.setState({
            isExportDrawerOpen: !this.state.isExportDrawerOpen
        })
    }

    handleOpenNewNotationDrawer = (key) => {
        this.getAxiosInstance().get(`/funcionario/${key}`)
            .then(res => {
                const selectedPatient = _.filter(this.state.diagnostics, (e) => e.key === key);
                const func = res.data && res.data.length > 0 ? res.data[0] : null;

                this.setState({
                    isNewNotationDrawerOpen: true,
                    selectedPatient: selectedPatient[0],
                    postData: selectedPatient[0],
                    statusAtualId: func && func.statusAtual ? func.statusAtual.id : _.first(_.filter(this.state.statusPessoa, s => s.key === 0)).id,
                    statusCovidId: func && func.statusCovid ? func.statusCovid.id : _.first(_.filter(this.state.statusDoenca, s => s.key === 0)).id,
                })
            });
    }

    handleOpenEditDrawer = (key) => {
        let selectedPatient = _.first(_.filter(this.state.diagnostics, (e) => e.key === key));

        let postData = {
            ...selectedPatient,
            haste: selectedPatient.haste.id,
            status: selectedPatient.status.id,
            medicalUnit: selectedPatient.medicalUnit.id,
            touchpoints: selectedPatient.touchpoints
        }

        this.setState({
            isEditDrawerOpen: true,
            selectedPatient: selectedPatient,
            postData: postData
        })
    }

    handleOpenGoogleMaps = (key) => {
        let selectedPatient = _.filter(this.state.diagnostics, (e) => e.key === key);
        window.open("https://www.google.com/maps/search/?api=1&query=" + selectedPatient[0].location.lat + "," + selectedPatient[0].location.lng + "", '_blank');
    }

    handleChangeStatus = (e) => {
        this.setState({
            postData: {
                ...this.state.postData,
                status: e.target.value
            }
        })


    }

    handleChangeMonth = (e) => {
        this.setState({
            selectedMonth: e
        })
    }

    handleChangeYear = (e) => {
        this.setState({
            selectedYear: e
        })
    }

    handleChangeHaste = (e) => {
        this.setState({
            postData: {
                ...this.state.postData,
                haste: e.target.value
            }
        })
    }

    handleRegisterAnotation = (e) => {
        if (!this.state.statusAtualId) {
            Alert.error("Indique o grau de risco do funcionário!", {
                position: 'bottom-right',
                effect: 'stackslide',
            });
            return;
        }

        if (!this.state.statusCovidId) {
            Alert.error("Indique status da COVID do funcionário!", {
                position: 'bottom-right',
                effect: 'stackslide',
            });
            return;
        }

        this.setState({
            isRegisteringAnnotation: true
        })
        this.getAxiosInstance().post('/diagnosis/annotation', {
            key: this.state.selectedPatient.key,
            text: this.state.anotation,
            statusAtual: this.state.statusAtualId,
            statusCovid: this.state.statusCovidId
        }).then(() => {
            Alert.success("Anotação registrada! 📋", {
                position: 'bottom-right',
                effect: 'stackslide',
            });

            this.setState({
                isRegisteringAnnotation: false,
                isNewNotationDrawerOpen: false,
                anotation: null
            })

            window.location.reload();
        })
    }

    handleChangeAnnotation = (e) => {

        this.setState({
            anotation: e.target.value
        })
    }

    handleChangeStatusAtual = (e) => {
        this.setState({
            statusAtualId: e.target.value
        })
    }

    handleChangeStatusCovid = (e) => {
        this.setState({
            statusCovidId: e.target.value
        })
    }

    handleChangeRespiratoryChart = (e) => {
        this.setState({
            postData: {
                ...this.state.postData,
                respiratoryChart: e
            }
        })
    }

    handleAddTouchPoint = (e) => {
        const newTouchPoint = {
            key: this.state.selectedPatient.key,
            name: "",
            phone: "",
            isCompany: true
        };
        this.setState({
            postData: {
                ...this.state.postData,
                touchpoints: [...this.state.postData.touchpoints, newTouchPoint]
            }
        });
    }

    handleChangeTouchpointRow = idx => e => {

        const touchpoints = [...this.state.postData.touchpoints];

        if (e.target) {
            touchpoints[idx][e.target.name] = e.target.value;
        } else {
            touchpoints[idx].isCompany = e;
        }

        this.setState({
            postData: {
                ...this.state.postData,
                touchpoints
            }
        });

    }

    handleChangeObservationDays = (e) => {
        this.setState({
            postData: {
                ...this.state.postData,
                observationDays: e.target.value
            }
        })
    }

    handleExport = (e) => {
        const year = this.state.selectedYear;
        const month = ("0" + (this.state.selectedMonth + 1)).slice(-2);
        this.setState({
            isExportDrawerOpen: false
        });
        window.open(`${process.env.REACT_APP_API_URL}/diagnosis/xls?data_referencia=${year}-${month}-01`, '_blank')
    }

    handleEdit = (e) => {

        this.setState({
            isEditing: true,
        })

        this.getAxiosInstance().post("/diagnosis", this.state.postData).then(() => {
            this.getAxiosInstance().post("/diagnosis/touchpoints", this.state.postData.touchpoints).then(() => {
                Alert.success("Diagnostico atualizado! 📋", {
                    position: 'bottom-right',
                    effect: 'stackslide',
                });

                this.setState({
                    isEditing: false,
                    isEditDrawerOpen: false,
                    postData: null
                })

                window.location.reload();
            })
        })
    }

    getColumnSearchProps = (dataIndex, textFormatter) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                this.searchInput = node;
              }}
              placeholder={`Filtrar ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              icon="search"
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Filtar
            </Button>
            <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Limpar
            </Button>
          </div>
        ),
        filterIcon: filtered => (
          <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
          (textFormatter ? textFormatter(record[dataIndex]) : record[dataIndex].toString())
            .toLowerCase()
            .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => this.searchInput.select());
          }
        },
        render: value =>
          this.state.searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[this.state.searchText]}
              autoEscape
              textToHighlight={textFormatter ? textFormatter(value) : value.toString()}
            />
          ) : (
            textFormatter ? textFormatter(value) : value.toString()
          ),
    })

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
          searchText: selectedKeys[0],
          searchedColumn: dataIndex,
        });
    }

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    }

    onTableDataSourceChange = (pagination, filters, sorter, extra) => {
        this.setState({ currentDataSource: extra.currentDataSource });
    }

    render() {
        return (
            <Layout style={{ minHeight: '100vh' }} >
                <Sider style={{ background: "#fff", zIndex: 0 }} collapsed={true}>
                    <div style={{ padding: 10, textAlign: "center" }}>
                        <img width="40px" src={require('./../../assets/logo-bbm-small.png')} alt="logo-empresa" />
                    </div>
                    <Menu mode="inline">
                        <Menu.Item key="1">
                            <Icon type="dashboard" />
                            <span>Dashboard</span>
                            <Link to={ROUTES.DASHBOARD} />
                        </Menu.Item>
                        <Menu.Item key="2" path>
                            <Icon type="schedule" />
                            <span>Funcionários</span>
                            <Link to={ROUTES.FUNCIONARIOS} />
                        </Menu.Item>
                        <Menu.Item key="3" path>
                            <a href={ROUTES.INDICADORES} target="_blank" rel="noopener noreferrer">
                                <Icon type="area-chart" />
                                <span>Indicadores</span>
                            </a>
                        </Menu.Item>
                        <Menu.Item key="3" path>
                            <a href={ROUTES.INICIO_JORNADA} target="_blank" rel="noopener noreferrer">
                                <Icon type="area-chart" />
                                <span>Início de Jornada</span>
                            </a>
                        </Menu.Item>
                        <Menu.Item className={"logout-menu-item"} key="3" onClick={this.props.firebase.doSignOut} >
                            <Icon type="logout" />
                            <span>Logout</span>
                        </Menu.Item>
                    </Menu>
                </Sider>
                <Content style={{ padding: '50px' }}>
                    <Row>
                        <Col md={24}>
                            <Card>
                                {
                                    this.state.isLoading ?
                                        <Skeleton active />
                                        :
                                        <div>
                                            <Row>
                                                <Button onClick={() => { window.open(process.env.REACT_APP_DIAGNOSTICO_URL, '_blank'); }} icon={"plus"} className={"btn-brand mr-15"} type="primary" style={{ marginBottom: 16 }}>
                                                    Novo diagnóstico
                                                </Button>
                                                <Button onClick={() => { this.handleOpenExportDrawer() }} icon={"file-excel"} className={"btn-neutral"} type="primary" style={{ marginBottom: 16 }}>
                                                    Exportar para excel
                                                </Button>
                                                <label style={{float: 'right'}}>Registros: {this.state.currentDataSource.length}</label>
                                            </Row>
                                            <Table ref={this.grid}
                                                pagination={{ pageSize: 50 }}
                                                dataSource={this.state.diagnostics}
                                                size="small"
                                                locale={{
                                                    emptyText: "Ainda não temos nenhum diagnóstico"
                                                }}
                                                onChange={this.onTableDataSourceChange}
                                                columns={[
                                                    {
                                                        title: 'CPF',
                                                        dataIndex: 'key',
                                                        key: '#key',
                                                        ...this.getColumnSearchProps('key'),
                                                    },
                                                    {
                                                        title: 'Nome',
                                                        dataIndex: 'name',
                                                        key: 'name',
                                                        sorter: (a, b) => a.name.localeCompare(b.name),
                                                        ...this.getColumnSearchProps('name'),
                                                    },
                                                    {
                                                        title: 'Grupo',
                                                        dataIndex: 'status',
                                                        filters: this.state.status.map(e => {
                                                            return {
                                                                text: e.label,
                                                                value: e.id
                                                            }
                                                        }),
                                                        onFilter: (filterValue, record) => record.status.id === filterValue,
                                                        key: 'status',
                                                        render: status => <Tag>{status.label}</Tag>

                                                    },
                                                    {
                                                        title: 'Grau Risco',
                                                        dataIndex: 'statusAtual.label',
                                                        key: 'statusAtual.label',
                                                        render: statusAtual => <Tag>{statusAtual}</Tag>,
                                                        onFilter: (filterValue, record) => record.statusAtual && record.statusAtual.id === filterValue,
                                                        filters: this.state.statusPessoa.map(e => {
                                                            return {
                                                                text: e.label,
                                                                value: e.id
                                                            }
                                                        }),
                                                    },
                                                    {
                                                        title: 'Status COVID',
                                                        dataIndex: 'statusCovid.label',
                                                        key: 'statusCovid.label',
                                                        render: statusCovid => <Tag>{statusCovid}</Tag>,
                                                        onFilter: (filterValue, record) => record.statusCovid && record.statusCovid.id === filterValue,
                                                        filters: this.state.statusDoenca.map(e => {
                                                            return {
                                                                text: e.label,
                                                                value: e.id
                                                            }
                                                        }),
                                                    },
                                                    {
                                                        title: 'Data',
                                                        dataIndex: 'createdAt',
                                                        key: 'createdAt',
                                                        ...this.getColumnSearchProps('createdAt', createdAt => moment.utc(createdAt).tz("America/Sao_Paulo").format("DD/MM/YYYY HH:mm")),
                                                    },
                                                    {
                                                        title: 'Área',
                                                        dataIndex: 'medicalUnit',
                                                        key: 'medicalUnit',
                                                        filters: this.state.medicalUnit.map(e => {
                                                            return {
                                                                text: e.label,
                                                                value: e.id
                                                            }
                                                        }),
                                                        render: medicalUnit => medicalUnit ? medicalUnit.label : "#",
                                                        onFilter: (filterValue, record) => record.medicalUnit && record.medicalUnit.id === filterValue
                                                    },
                                                    {
                                                        title: 'Origem',
                                                        dataIndex: 'origin',
                                                        key: 'origin',
                                                        ...this.getColumnSearchProps('origin', origin => origin ? "Autoatendimento" : "Auditor"),
                                                    },
                                                    {
                                                        title: '',
                                                        dataIndex: 'key',
                                                        key: 'key',
                                                        render: (key) =>
                                                            <Dropdown overlay={
                                                                <Menu>
                                                                    <Menu.Item onClick={() => { this.handleOpenEditDrawer(key) }}>Editar</Menu.Item>
                                                                    <Menu.Item onClick={() => { this.handleOpenNewNotationDrawer(key) }}>Nova anotação SSMAQ e status</Menu.Item>
                                                                    <Menu.Item onClick={() => { this.handleOpenGoogleMaps(key) }}>Ver no mapa</Menu.Item>
                                                                </Menu>
                                                            }>
                                                                <a href={"#"} className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                                                    Ações <Icon type="down" />
                                                                </a>
                                                            </Dropdown>
                                                    },
                                                ]}
                                                expandedRowRender={record =>
                                                    <div className={"mt-10"}>
                                                        <h3 className={"mb-10"}><Icon type={"user"} /> Dados gerais: </h3>
                                                        <Descriptions>
                                                            <Descriptions.Item label="Telefone">{record.phone}</Descriptions.Item>
                                                            <Descriptions.Item label="OBS">
                                                                <Tooltip placement={"bottomRight"} title={record.obs}>
                                                                    {
                                                                        _.truncate(record.obs)
                                                                    }
                                                                    <Icon className={"ml-10"} type={"info-circle"} />
                                                                </Tooltip>
                                                            </Descriptions.Item>
                                                            <Descriptions.Item label="Temperatura">{TEMPERATURE[record.temperature]}</Descriptions.Item>
                                                            <Descriptions.Item label="Viajou?"><Tag>{record.hasTravel ? "SIM" : "NÃO"}</Tag></Descriptions.Item>
                                                            <Descriptions.Item label="Quem informou">{record.sender.name} - {record.sender.email}</Descriptions.Item>
                                                            <Descriptions.Item label="Maior de 60 anos?"><Tag>{record.sixtyMore ? "SIM" : "NÃO"}</Tag></Descriptions.Item>
                                                            <Descriptions.Item label="Contato com suspeito de infecção?"><Tag>{record.contactSuspect ? "SIM" : "NÃO"}</Tag></Descriptions.Item>
                                                        </Descriptions>
                                                        <Divider />

                                                        <h3 className={"mb-10"}><Icon type={"warning"} /> Sintomas: {(_.isEmpty(record.respiratoryChart) && _.isEmpty(record.chronicChart)) && <small style={{ fontSize: 12, color: "#cccccc" }}><i>Nenhum sintoma foi informado para esse paciente no momento</i></small>}</h3>
                                                        {(!_.isEmpty(record.respiratoryChart) || !_.isEmpty(record.chronicChart)) &&
                                                            <Descriptions>
                                                                {
                                                                    !_.isEmpty(record.respiratoryChart) &&
                                                                    <Descriptions.Item label="Respiratórios">
                                                                        {record.respiratoryChart.map((e, i) =>
                                                                            <Tag color="geekblue" key={RESPIRATORY_CHART[e]}>{RESPIRATORY_CHART[e]}</Tag>
                                                                        )}
                                                                    </Descriptions.Item>
                                                                }
                                                                {
                                                                    !_.isEmpty(record.chronicChart) &&
                                                                    <Descriptions.Item label="Crônicos">
                                                                        {record.chronicChart.map((e, i) =>
                                                                            <Tag color="volcano" key={CHRONIC_CHART[e]}>{CHRONIC_CHART[e]}</Tag>
                                                                        )}
                                                                    </Descriptions.Item>
                                                                }
                                                            </Descriptions>
                                                        }
                                                        <Divider />

                                                        <h3 className={"mb-10"}><Icon type={"medicine-box"} /> Anotações SSMAQ:  {_.isEmpty(record.annotations) && _.isEmpty(record.touchpoints) && <small style={{ fontSize: 12, color: "#cccccc" }}><i>Nenhuma anotação SSMAQ foi feita para esse paciente no momento</i></small>}</h3>
                                                        {(!_.isEmpty(record.annotations) || !_.isEmpty(record.touchpoints)) &&
                                                            <List
                                                                itemLayout="horizontal"
                                                                dataSource={_.reverse(_.sortBy([...record.annotations, ...record.touchpoints], [(o) => o.createdAt]))}
                                                                renderItem={item => (
                                                                    <List.Item>
                                                                        <List.Item.Meta
                                                                            description={
                                                                                <div>
                                                                                    <strong>{moment.utc(item.createdAt).tz("America/Sao_Paulo").format("DD/MM/YYYY HH:mm")}</strong> - {item.text || (item.isCompany ? "O paciente teve contato com o funcionário da BBM Logística " + item.name + " com telefone: " + item.phone : "O paciente teve contato com " + item.name + " e o telefone para contato é: " + item.phone)}
                                                                                </div>
                                                                            }
                                                                        />
                                                                    </List.Item>
                                                                )}
                                                            />
                                                        }
                                                    </div>
                                                }
                                            >
                                            </Table>
                                            {this.state.postData &&
                                                <div>
                                                    <Drawer
                                                        width={600}
                                                        closable={true}
                                                        maskClosable={true}
                                                        visible={this.state.isNewNotationDrawerOpen}
                                                        onClose={() => { this.setState({ isNewNotationDrawerOpen: false }) }}
                                                        title={"Nova anotação SSMAQ"}
                                                    >
                                                        <TextArea value={this.state.anotation} onChange={this.handleChangeAnnotation} placeholder={"Anotações..."} rows={5} />
                                                        <Divider />

                                                        <p><strong>Grau de Risco</strong></p>
                                                        <Radio.Group size={"small"} style={{ width: "100%" }} onChange={this.handleChangeStatusAtual}>
                                                            {
                                                                this.state.statusPessoa.map(e =>
                                                                    <Radio.Button
                                                                        style={{ width: (100 / this.state.statusPessoa.length) + "%" }}
                                                                        key={e.id}
                                                                        checked={e.id === this.state.statusAtualId}
                                                                        value={e.id}>
                                                                        {e.label}
                                                                    </Radio.Button>
                                                                )
                                                            }
                                                        </Radio.Group>
                                                        <div style={{marginTop: "10px", "line-height": "5px"}}>
                                                            <p>Baixo - Contato com pessoas com COVID-19</p>
                                                            <p>Médio - Em casa</p>
                                                            <p>Grave - Hospitalizado</p>
                                                            <p>Crítico - Entubado</p>
                                                            <p>Liberado - Liberado para trabalhar na empresa</p>
                                                            <p>Óbito - Falecimento</p>
                                                        </div>
                                                        <Divider />

                                                        <p><strong>Status COVID</strong></p>
                                                        <Radio.Group size={"small"} style={{ width: "100%" }} onChange={this.handleChangeStatusCovid}>
                                                            {
                                                                this.state.statusDoenca.map(e =>
                                                                    <Radio.Button
                                                                        style={{ width: (100 / this.state.statusDoenca.length) + "%" }}
                                                                        key={e.id}
                                                                        checked={e.id === this.state.statusCovidId}
                                                                        value={e.id}>
                                                                        {e.label}
                                                                    </Radio.Button>
                                                                )
                                                            }
                                                        </Radio.Group>
                                                        <Divider />

                                                        <div
                                                            style={{
                                                                position: 'absolute',
                                                                left: 0,
                                                                bottom: 0,
                                                                width: '100%',
                                                                borderTop: '1px solid #e9e9e9',
                                                                padding: '10px 16px',
                                                                background: '#fff',
                                                                textAlign: 'right',
                                                            }}
                                                        >
                                                            <Button loading={this.state.isRegisteringAnnotation} block icon="plus" onClick={this.handleRegisterAnotation} type="primary" className="btn-brand">
                                                                Adicionar anotação
                                                            </Button>
                                                        </div>
                                                    </Drawer>
                                                    <Drawer
                                                        width={600}
                                                        closable={true}
                                                        maskClosable={true}
                                                        visible={this.state.isEditDrawerOpen}
                                                        onClose={() => { this.setState({ isEditDrawerOpen: false }) }}
                                                        title={
                                                            "Editar dados do paciente: " + this.state.selectedPatient.name
                                                        }
                                                    >

                                                        <p><strong>Status da avaliação: </strong></p>
                                                        <Radio.Group size={"small"} onChange={this.handleChangeStatus}>
                                                            {
                                                                this.state.status.map(e =>
                                                                    <Radio.Button
                                                                        key={e.id}
                                                                        checked={e.id === this.state.postData.status}
                                                                        value={e.id}>
                                                                        {e.label}
                                                                    </Radio.Button>
                                                                )
                                                            }
                                                        </Radio.Group>
                                                        <Divider />
                                                        {/*Se status observação*/}
                                                        {this.state.postData.status == "S84ju8bNFFlzAw8iLfZ8" &&
                                                            <div>
                                                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                                    <span>
                                                                        Ok... E quantos dias deve ficar em observação?
                                                                    </span>
                                                                    <Input value={this.state.postData.observationDays} type={"number"} onChange={this.handleChangeObservationDays} placeholder={"Nº de dias"} style={{ width: "120px" }} />
                                                                </div>
                                                                <Divider />
                                                            </div>
                                                        }
                                                        <p><strong>Sintomas apresentados: </strong></p>
                                                        <Checkbox.Group value={this.state.postData.respiratoryChart} onChange={this.handleChangeRespiratoryChart} style={{ width: '100%' }}>
                                                            <Row gutter={30}>
                                                                {
                                                                    RESPIRATORY_CHART.map((e, i) =>
                                                                        <Col key={i} span={Math.floor(24 / RESPIRATORY_CHART.length)}>
                                                                            <Checkbox className={"custom-checkbox"} key={i} value={_.findIndex(RESPIRATORY_CHART, (o) => { return o === e })} >{e}</Checkbox>
                                                                        </Col>
                                                                    )
                                                                }
                                                            </Row>
                                                        </Checkbox.Group>
                                                        <Divider />

                                                        {/* <p><strong>Nivel de urgência: </strong></p>
                                                        <Radio.Group size={"small"} style={{ width: "100%" }} onChange={this.handleChangeHaste}>
                                                            {
                                                                this.state.hastes.map(e =>
                                                                    <Radio.Button
                                                                        style={{ width: (100 / this.state.hastes.length) + "%" }}
                                                                        key={e.id}
                                                                        checked={e.id === this.state.postData.haste}
                                                                        value={e.id}>
                                                                        {e.label}
                                                                    </Radio.Button>
                                                                )
                                                            }
                                                        </Radio.Group>
                                                        <Divider /> */}


                                                        <div className={"mb-15"} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                            <strong>Adicione as pessoas com que esse paciente teve contato. </strong>
                                                            <Button onClick={this.handleAddTouchPoint} className={"btn-brand"} icon={"plus"} />
                                                        </div>


                                                        <table className={"mb-50"} style={{ width: "100%" }}>
                                                            <thead className="ant-table-thead">
                                                                <tr>
                                                                    <th>Nome</th>
                                                                    <th>Telefone</th>
                                                                    <th>É BBM Logística?</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="ant-table-tbody">
                                                                {
                                                                    console.log(`RENDER: ${this.state.postData.touchpoints.length}`)
                                                                }
                                                                {
                                                                    !_.isEmpty(this.state.postData.touchpoints) &&
                                                                    this.state.postData.touchpoints.map((item, idx) => (
                                                                        <tr key={idx}>
                                                                            <td>
                                                                                <Input
                                                                                    placeholder="Name"
                                                                                    name="name"
                                                                                    value={this.state.postData.touchpoints[idx].name}
                                                                                    onChange={this.handleChangeTouchpointRow(idx)}
                                                                                    className="form-control"
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Input
                                                                                    placeholder="Telefone"
                                                                                    name="phone"
                                                                                    value={this.state.postData.touchpoints[idx].phone}
                                                                                    onChange={this.handleChangeTouchpointRow(idx)}
                                                                                    className="form-control"
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Switch
                                                                                    name="isCompany"
                                                                                    checked={this.state.postData.touchpoints[idx].isCompany}
                                                                                    onChange={this.handleChangeTouchpointRow(idx)}
                                                                                    className="form-control"
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                    ))

                                                                }
                                                            </tbody>
                                                        </table>


                                                        <div
                                                            style={{
                                                                position: 'absolute',
                                                                left: 0,
                                                                bottom: 0,
                                                                width: '100%',
                                                                borderTop: '1px solid #e9e9e9',
                                                                padding: '10px 16px',
                                                                background: '#fff',
                                                                textAlign: 'right',
                                                            }}
                                                        >
                                                            <Button loading={this.state.isEditing} block icon="sync" onClick={this.handleEdit} type="primary" className="btn-brand">
                                                                Atualizar
                                                            </Button>
                                                        </div>
                                                    </Drawer>
                                                </div>
                                            }


                                            {this.state.isExportDrawerOpen &&
                                                <div>
                                                    <Drawer
                                                        width={600}
                                                        closable={true}
                                                        maskClosable={true}
                                                        visible={this.state.isExportDrawerOpen}
                                                        onClose={() => { this.setState({ isExportDrawerOpen: false }) }}
                                                        title={"Exportar para Excel"}
                                                    >

                                                        <p><strong>Selecione um mês</strong></p>
                                                        <Select 
                                                            defaultValue={this.state.selectedMonth} 
                                                            style={{ height: 50 }} 
                                                            onChange={this.handleChangeMonth} 
                                                            className={"ant-input-affix-wrapper ant-input-affix-wrapper-lg"} 
                                                            placeholder="Mês"
                                                        >
                                                            {MESES.map((d, k) =>
                                                                <Option value={k}>{d}</Option>
                                                            )}
                                                        </Select>

                                                        <p><strong>Selecione um ano</strong></p>
                                                        <Select defaultValue={this.state.selectedYear} style={{ height: 50 }} onChange={this.handleChangeYear} className={"ant-input-affix-wrapper ant-input-affix-wrapper-lg"} showSearch placeholder="Ano">
                                                            {ANOS.map(d =>
                                                                <Option value={d}>{d}</Option>
                                                            )}
                                                        </Select>

                                                        <div
                                                            style={{
                                                                position: 'absolute',
                                                                left: 0,
                                                                bottom: 0,
                                                                width: '100%',
                                                                borderTop: '1px solid #e9e9e9',
                                                                padding: '10px 16px',
                                                                background: '#fff',
                                                                textAlign: 'right',
                                                            }}
                                                        >
                                                            <Button block icon="file-excel" onClick={this.handleExport} type="primary" className="btn-brand">
                                                                Gerar Arquivo
                                                            </Button>
                                                        </div>
                                                    </Drawer>
                                                </div>
                                            }
                                        </div>
                                }
                            </Card>
                        </Col>
                    </Row>
                </Content >
            </Layout >
        );
    }
}

// just check if its not null
const condition = authUser => {
    if (!authUser) {
        createBrowserHistory().push(ROUTES.SIGN_IN);
        window.location.reload();
    }
    return  !!authUser;
}
export default compose(
    withEmailVerification,
    withAuthorization(condition),
    withRouter,
    withFirebase,
)(Dashboard);