import app from "firebase/app";
import "firebase/auth";
import 'firebase/firestore';
import ReactGA from 'react-ga';
import axios from 'axios';

import { createBrowserHistory } from "history";
import * as ROUTES from '../../constants/routes';

const config = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

class Firebase {
    constructor() {
        // app.initializeApp(config);

        // this.auth = app.auth();
        // this.db = app.firestore();

        const storedValue = localStorage.getItem('authUser');
        this.auth = {
            user: storedValue ? JSON.parse(storedValue) : null
        };
        this.handleAuthStateChangedEvent();
    }

    handleAuthStateChangedEvent = () => {
        setTimeout(() => {
            if (this.auth.onAuthStateChanged) {
                this.auth.onAuthStateChanged(this.auth.user);
            } else {
                this.handleAuthStateChangedEvent();
            }
        }, 250);
    }

    http = () => axios.create({ baseURL: process.env.REACT_APP_API_URL });

    // *** Auth API ***

    doCreateUserWithEmailAndPassword = (email, password) =>
        this.auth.createUserWithEmailAndPassword(email, password);

    doSignInWithEmailAndPassword = (email, password) =>
        this.http().post('auth/signin', { identificacao: email, password })
            .then(res => {
                if (this.auth.onAuthStateChanged) {
                    this.auth.onAuthStateChanged(res.data);
                }
            });
        // this.auth.signInWithEmailAndPassword(email, password);

    doSignOut = () => {
        this.auth.onAuthStateChanged(null);
        localStorage.removeItem('authUser');
        createBrowserHistory().push(ROUTES.SIGN_IN);
        window.location.reload();
    }

    doPasswordReset = email =>
        this.http().post('auth/password-reset', { email: email});

    doPasswordUpdate = (resetPwdId, password) =>
        this.http().put('auth/password-change', {
            resetPwdId: resetPwdId,
            password: password
        });

    doSendEmailVerification = () =>
        this.auth.currentUser.sendEmailVerification({
            url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
        });

    // *** Merge Auth and DB User API *** //

    onAuthUserListener = (next, fallback) =>
        this.auth.onAuthStateChanged = authUser => {
            if (authUser) {
                // merge auth and db user
                const sessionUser = {
                    jwtToken: authUser.id,
                    uid: authUser.id,
                    email: authUser.email,
                    emailVerified: true,
                    //providerData: authUser.providerData,
                    ...authUser,
                };

                //Integration with GA
                ReactGA.set({
                    userId: sessionUser.uid
                });

                ReactGA.event({
                    category: sessionUser.uid,
                    action: 'User logged in',
                });

                this.auth.user = sessionUser;
                next(sessionUser);
            } else {
                fallback();
            }
        };

    // *** User API ***

    // user = uid => this.db.collection('users').doc(uid);

    // users = () => this.db.collection('users');
}

export default Firebase;